<template>
	<div class="task-area">
		<el-row class="function-area">
			<el-button type="primary" size="mini" @click="showAddDialog">添加任务</el-button>
		</el-row>
		<el-table :data="taskList" style="width: 100%">
			<el-table-column prop="taskName" label="任务名称" width="150"></el-table-column>
			<el-table-column prop="running" label="运行状态" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row['running']" type="success">运行中</el-tag>
					<el-tag v-else type="warning">未运行</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="running" label="是否有效" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row['isValid']" type="success">有效</el-tag>
					<el-tag v-else type="warning">无效</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="taskRule" label="任务规则" width="130"></el-table-column>
			<el-table-column prop="xiguaChannelName" label="西瓜频道" width="130"></el-table-column>
			<el-table-column prop="youtubeChannelName" label="YouTube频道" width="130"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click.native.prevent="showEditDialog(scope.$index)" type="text" size="small">修改</el-button>
					<el-button @click.native.prevent="toRestartTask(scope.$index)" type="text" size="small">重启</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :title="addOrEdit === 'ADD' ? '添加任务' : '修改任务'" :visible.sync="addOrEditDialog">
			<el-form :model="addOrEditTaskFormData" :rules="addOrEditTaskFormRules" ref="addForm" label-width="110px" size="mini">
				<el-form-item label="任务名称" prop="taskName">
					<el-input v-model="addOrEditTaskFormData.taskName" placeholder="请输入任务名称"></el-input>
				</el-form-item>
				<el-form-item label="任务规则" prop="taskRule">
					<el-input v-model="addOrEditTaskFormData.taskRule" placeholder="请输入Cron风格任务规则"></el-input>
				</el-form-item>
				<el-form-item label="西瓜频道" prop="xiguaChannelId">
					<el-select v-model="addOrEditTaskFormData.xiguaChannelId" placeholder="请选择西瓜频道">
						<el-option v-for="item in xiguaChannels" :label="item['channelName']" :value="item['id']" :key="item['id']"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="YouTube频道" prop="youtubeChannelId">
					<el-select v-model="addOrEditTaskFormData.youtubeChannelId" placeholder="请选择YouTube频道">
						<el-option v-for="item in youTubeChannels" :label="item['channelName']" :value="item['id']" :key="item['id']"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否有效" prop="isValid">
					<el-switch v-model="addOrEditTaskFormData.isValid" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input v-model="addOrEditTaskFormData.note"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="toAddOrEditTask('addForm')" :loading="addOrEditLoading">确 定</el-button>
					<el-button @click="toResetForm">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {getAllTasks, restartTask, addNewTask, updateTaskInfo} from "@/api/task";
import {getAllXiGuaChannels, getAllYouTubeChannels} from "@/api/channel";

export default {
	name: "TaskManage",
	data() {
		return {
			xiguaChannels: [],
			youTubeChannels: [],
			taskList: [],

			addOrEdit: "",
			addOrEditTaskFormData: {
				id: null, taskName: null, taskRule: null, xiguaChannelId: null, youtubeChannelId: null, isValid: null, note: null
			},
			addOrEditTaskFormRules: {
				taskName: [
					{required: true, message: '请输入活动名称', trigger: 'blur'},
					{min: 2, max: 15, message: '长度在2到15个字符', trigger: 'blur'}
				],
				taskRule: [{required: true, message: '请输入Cron风格任务规则', trigger: 'blur'}],
				xiguaChannelId: [{required: true, message: '请选择西瓜频道', trigger: 'change'}],
				youtubeChannelId: [{required: true, message: '请选择YouTube频道', trigger: 'change'}]
			},
			addOrEditDialog: false,
			addOrEditLoading: false
		}
	},
	created() {
		this.initData();
	},
	methods: {
		async initData() {
			// 初始化数据
			const loading = this.$loading({lock: true, text: '加载中', spinner: 'el-icon-loading', background: 'rgba(243,239,239,0.7)'});
			const ret1 = await getAllXiGuaChannels().catch((error) => {
				loading.close();
				return this.$message.error('获取西瓜频道列表失败：' + error);
			});
			if (!ret1['success']) {
				loading.close();
				return this.$message.error('获取西瓜频道列表失败：' + ret1.data);
			}
			this.xiguaChannels = ret1.data;

			const ret2 = await getAllYouTubeChannels().catch((error) => {
				loading.close();
				return this.$message.error('获取Youtube频道列表失败：' + error);
			});
			if (!ret2['success']) {
				loading.close();
				return this.$message.error('获取Youtube频道列表失败：' + ret2.data);
			}
			this.youTubeChannels = ret2.data;

			this.toGetAllTasks();
			loading.close();
		},
		toGetAllTasks() {
			// 获取所有任务列表
			getAllTasks().then(response => {
				if (!response['success']) return this.$message.error('获取Task列表失败：' + response.data);
				const list = response.data;
				// 绑定name
				for (let i = 0; i < list.length; i++) {
					list[i].xiguaChannelName = this.xiguaChannels.find(item => item.id === list[i].xiguaChannelId).channelName;
					list[i].youtubeChannelName = this.youTubeChannels.find(item => item.id === list[i].youtubeChannelId).channelName;
				}
				this.taskList = list;
			}).catch((error) => {
				this.$message.error('获取Task列表失败：' + error);
			});
		},
		showAddDialog() {
			this.addOrEdit = "ADD";
			this.addOrEditDialog = true;
		},
		showEditDialog(index) {
			this.addOrEdit = "EDIT";
			this.addOrEditDialog = true;
			this.addOrEditTaskFormData = JSON.parse(JSON.stringify(this.taskList[index]));
		},
		toAddOrEditTask(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				this.addOrEditLoading = true;
				if (this.addOrEdit === 'ADD') {
					// 创建Task
					addNewTask(this.addOrEditTaskFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('添加任务失败：' + response.data);
						this.$message.success('添加任务成功');
						this.toGetAllTasks();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('添加任务失败：' + error);
					});
				} else {
					// 修改Task
					updateTaskInfo(this.addOrEditTaskFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('修改任务失败：' + response.data);
						this.$message.success('修改任务成功');
						this.toGetAllTasks();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('修改任务失败：' + error);
					});
				}
			});
		},
		toResetForm() {
			// 重置form
			this.addOrEditTaskFormData = {
				id: null, taskName: null, taskRule: null, xiguaChannelId: null,
				youtubeChannelId: null, isValid: null, note: null
			};
			this.addOrEditDialog = false;
		},
		toRestartTask(index) {
			// 重启任务
			const loading = this.$loading({lock: true, text: '加载中', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
			restartTask(this.taskList[index].id).then(response => {
				if (!response['success']) {
					loading.close();
					return this.$message.error('重启任务失败：' + response.data);
				}
				this.$message.success('重启任务成功');
				loading.close();
			}).catch((error) => {
				loading.close();
				this.$message.error('重启任务失败：' + error);
			});
		}
	}
}
</script>

<style lang="less" scoped>
.task-area {
	margin: 10px;

	.function-area {
		margin: 10px 0;
	}
}
</style>